import { NextRequest } from 'next/server';

export const FIRST_VISIT_COOKIE_NAME = 'motivated:first_visit_epoch';
export const FIRST_VISIT_RND_COOKIE_NAME = 'motivated:first_visit_rnd';

export const decodePercentage = (
  text?: string,
): {
  value: number;
  text: string;
} => {
  if (!text) {
    return {
      value: 0,
      text: '',
    };
  }

  const regexPercentage = /^(\d+|(\.\d+))(\.\d+)?%$/;
  if (regexPercentage.test(text)) {
    return {
      value: parseFloat(text),
      text,
    };
  }

  return {
    value: 0,
    text,
  };
};

export function enm(data: Record<string, any>) {
  return btoa(JSON.stringify(data));
}

export const hasNumbers = (value: string) => {
  return /\d/.test(value);
};

export const hasUppercase = (value: string) => {
  return /[A-Z]/.test(value);
};

export const hasLowercase = (value: string) => {
  return /[a-z]/.test(value);
};

export const hasSymbols = (value: string) => {
  return /[!@#$%^&*]/.test(value);
};

/**
 * for serializing with JSON.stringify
 * some data cannot be serialized
 */
export function replacer(key: unknown, value: unknown) {
  if (value instanceof HTMLElement) {
    return '>>>HTMLElement<<<';
  }
  return value;
}

function buildFacebookClickIdFromFbclidQueryStringParam(
  firstVisitEpoch: string | null,
  fbclid: string | null,
) {
  if (!fbclid || !firstVisitEpoch) {
    return null;
  }

  return 'fb.1.' + firstVisitEpoch + '.' + fbclid;
}

// https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
function buildFacebookBrowserIdFromFirstVisitEpoch(
  firstVisitEpoch: string | null,
  firstVisitRandomCookie: string | null,
) {
  if (!firstVisitEpoch || !firstVisitRandomCookie) {
    return null;
  }

  return 'fb.1.' + firstVisitEpoch + '.' + firstVisitRandomCookie;
}

export function getMarketingParamsFromCookiesAndLandingPageUrl(
  request: NextRequest,
  landingPageUrl?: string | null,
) {
  try {
    const firstVisitEpoch =
      request?.cookies?.get(FIRST_VISIT_COOKIE_NAME)?.value ?? null;
    const randomNumber = Math.floor(Math.random() * 1000000).toString();
    const firstVisitRandomCookie =
      request?.cookies?.get(FIRST_VISIT_RND_COOKIE_NAME)?.value ?? randomNumber;

    const facebookClickId = request?.cookies?.get('_fbc')?.value ?? null;
    const facebookBrowserId = request?.cookies?.get('_fbp')?.value ?? null;

    let marketingParams: any = {
      firstVisitRandomCookie,
      firstVisitEpoch,
      lpParams: {},
    };

    let fbclid = null;

    if (landingPageUrl) {
      const url = new URL(landingPageUrl);
      const searchParams = url.searchParams;

      fbclid = searchParams.get('fbclid');
      // Put all querystring params into marketingParams
      searchParams.forEach((value, key) => {
        marketingParams.lpParams[key] = value;
      });
    }

    marketingParams['facebookClickId'] =
      facebookClickId ??
      buildFacebookClickIdFromFbclidQueryStringParam(firstVisitEpoch, fbclid);
    marketingParams['facebookBrowserId'] =
      facebookBrowserId ??
      buildFacebookBrowserIdFromFirstVisitEpoch(
        firstVisitEpoch,
        firstVisitRandomCookie,
      );

    if (marketingParams['facebookClickId'] === null) {
      delete marketingParams['facebookClickId'];
    }

    if (marketingParams['facebookBrowserId'] === null) {
      delete marketingParams['facebookBrowserId'];
    }

    return marketingParams;
  } catch (e) {
    console.error('Error getting marketing params', {
      error: e,
      landingPageUrl,
    });
    return null;
  }
}

export function getUserIp(req: NextRequest) {
  try {
    const forwardedFor = req.headers.get('x-forwarded-for');
    return forwardedFor?.substring(0, forwardedFor?.indexOf(',')) || 'N/A';
  } catch (e) {
    return 'N/A';
  }
}

export function getUserIp2(req: NextRequest) {
  try {
    const forwardedFor = req.headers.get('x-forwarded-for');
    if (forwardedFor) {
      return forwardedFor?.substring(0, forwardedFor?.indexOf(','));
    }
    return '';
  } catch (e) {
    return '';
  }
}

export function getDatadogSessionId(req: NextRequest) {
  try {
    const datadogRumCookie = req.cookies.get('_dd_s')?.value;
    const parsedDatadogRumCookie = datadogRumCookie
      ? new URLSearchParams(datadogRumCookie)
      : null;
    return parsedDatadogRumCookie?.get('id') || null;
  } catch (e) {
    console.error('Failed to get datadog session id', e);
    return null;
  }
}
