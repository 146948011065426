import mixpanel, { Dict, Query } from 'mixpanel-browser';
import { datadogRum } from '@datadog/browser-rum';

const isActive =
  true ||
  process.env.NODE_ENV === 'production' ||
  process.env.FLAG_LOCAL_MIXPANEL === '1';
const MIXPANEL_TOKEN = process.env.MIXPANEL_TOKEN ?? '';
const MIXPANEL_DEBUG = process.env.MIXPANEL_DEBUG === 'true';
const MIXPANEL_PROXY_URL = process.env.MIXPANEL_PROXY_URL ?? '';

export const CLICK_EVENT = 'Click';
export const PAGE_VIEW_EVENT = 'Page View';
export const LOGIN_SUCCESS_EVENT = 'Logged in';
export const LOGIN_FAILED_EVENT = 'Login failed';
export const REGISTRATION_FAILED_EVENT = 'Registration failed';
export const QUESTION_VALIDATION_ERROR = 'Question validation error';

mixpanel.init(MIXPANEL_TOKEN, {
  debug: MIXPANEL_DEBUG,
  persistence: 'localStorage',
  ...(MIXPANEL_PROXY_URL && { api_host: MIXPANEL_PROXY_URL }),
});

console.log('Mixpanel loaded successfully');

export const Mixpanel = {
  track: (name: string, args: Dict = {}) => {
    if (!isActive) {
      return;
    }
    try {
      // urls in data cause hydration warning
      // so we inject them here
      const url =
        typeof window !== 'undefined' ? window.location.href : undefined;
      switch (true) {
        case name === CLICK_EVENT: {
          if (
            args?.pageData &&
            typeof args.pageData === 'object' &&
            !args.pageData?.url
          ) {
            args.pageData.url = url;
          }
          break;
        }

        case name === PAGE_VIEW_EVENT: {
          if (!args.url) {
            args.url = url;
          }
          break;
        }
      }

      args.buildVersion = process.env.NEXT_PUBLIC_BUILD_VERSION;
      args.datadogSessionId = datadogRum?.getInternalContext()?.session_id;

      mixpanel.track(name, args);

      if (datadogRum != null) {
        args.mixpanelDistinctId = mixpanel.get_distinct_id();
        datadogRum.addAction(`Report Mixpanel ${name} event`, args);
      }
    } catch (e) {
      console.error(e);
    }
  },
  trackLinks: (query: Query, name: string) => {
    if (!isActive) {
      return;
    }
    try {
      mixpanel.track_links(query, name, {
        referrer: document.referrer,
      });
    } catch (e) {
      console.error(e);
    }
  },
  setIdentify: (customerId: string) => {
    if (!isActive) {
      return;
    }
    try {
      console.log('Mixpanel setIdentify customerId: ', customerId);
      mixpanel.identify(customerId);
    } catch (e) {
      console.error(e);
    }
  },
  getDistinctId: () => {
    if (!isActive) {
      return null;
    }
    try {
      return mixpanel.get_distinct_id();
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  reset: () => {
    if (!isActive) {
      return;
    }
    try {
      mixpanel.reset();
    } catch (e) {
      console.error(e);
    }
  },
};
